<template>
  <v-chip
    class="mx-2 my-2 text-uppercase"
    label
    :color="getStateColor()"
  >
    {{ getStateLabel() }}
  </v-chip>
</template>

<script>
export default {
  name: 'StateLabel',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStateLabel() {
      const type =  this.item['@type'] ?? ''
      const stateReason = this.item.state_reason ?? ''
      const state = this.item.state ?? ''
      const fullState = stateReason ? `${state}_${stateReason}` : state

      if (!type || !fullState) {
        return ''
      }

      return this.$i18n.t('state.' + type.toLowerCase() + '.' + fullState.toLowerCase() + '.label')
    },
    getStateColor() {
      const type =  this.item['@type'] ?? ''
      const stateReason = this.item.state_reason ?? ''
      const state = this.item.state ?? ''
      const fullState = stateReason ? `${state}_${stateReason}` : state

      if (!type || !fullState) {
        return 'grey'
      }

      return this.$i18n.t('state.' + type.toLowerCase() + '.' + fullState.toLowerCase() + '.color')
    },
  },
}
</script>
