<template>
  <v-alert
    dense
    border="left"
    :type="getRejectionType(item)"
  >
    {{ item.rejection_reason }}
  </v-alert>
</template>

<script>
export default {
  name: 'RejectionAlert',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getRejectionType(item) {
      if (!item.rejection_reason_type) {
        return ''
      }

      return this.$i18n.t('rejection.' + item.rejection_reason_type.toLowerCase() + '.type')
    },
  },
}
</script>
