<template>
  <v-chip
    class="mx-2 my-2 text-lowercase"
    label
    :color="getRejectionColor(item)"
  >
    {{ getRejectionLabel(item) }}
  </v-chip>
</template>

<script>
export default {
  name: 'RejectionLabel',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getRejectionLabel(item) {
      return this.$i18n.t('rejection.' + item.rejection_reason_type.toLowerCase() + '.label')
    },
    getRejectionColor(item) {
      return this.$i18n.t('rejection.' + item.rejection_reason_type.toLowerCase() + '.color')
    },
  },
}
</script>
